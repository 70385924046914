import Box from '@mui/material/Box'
import ClientTable from '../components/customers/ClientTable'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import NewClient from '../components/customers/newClient'

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Veerii LLC 
//       </Link>
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   )
// }

export default function Home() {
  return (
    <div>
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container sx={{ mt: 4, mb: 4 }}>
          <Box>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <NewClient/>
              <ClientTable />
            </Paper>
          </Box>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </Container>
      </Box>
    </div>
  )
}
