import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  Alert,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  TextField,
  Button,
  Avatar,
  CircularProgress,
} from '@mui/material'

import { UserContext } from '../App'

// function Copyright(props: any) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   )
// }

const theme = createTheme()

export default function SignUp() {
  const { setUser } = React.useContext(UserContext)
  const [form, setForm] = React.useState({})
  const [success, setSuccess] = React.useState(false)

  const navigate = useNavigate()

  const handleSubmit = event => {
    setSuccess(true)
    event.preventDefault()
    fetch(`${process.env.REACT_APP_API}/api/realtors/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
    })
      .then(res => res.json())
      .then(newUserCreated => {
        if (newUserCreated) {
          localStorage.setItem('veerii_user', JSON.stringify(newUserCreated))
          setUser(newUserCreated)
          setSuccess(false)
          navigate('/profile')
        }
      })
      .catch(err => console.error(err))
  }

  const handleForm = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <ThemeProvider theme={theme}>
      {success && <Alert severity="success">Thank you for signing up!</Alert>}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone Number"
                  type="phone"
                  id="phone"
                  autoComplete="phone-number"
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="company-name"
                  name="agency"
                  required
                  fullWidth
                  id="agency"
                  label="Company Name"
                  onChange={handleForm}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="realtor"
                  label="Realtor Number"
                  name="realtorNo"
                  type="number"
                  onChange={handleForm}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="url"
                  label="Preferred URL"
                  name="url"
                  onChange={handleForm}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>

            {!success ? (
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Sign Up
              </Button>
            ) : (
              <CircularProgress />
            )}

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
