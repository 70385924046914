import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import PropertiesTable from '../components/properties/PropertiesTable'
import Toolbar from '@mui/material/Toolbar'
import AddProperty from '../components/properties/addProperty'

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Veerii LLC 
//       </Link>
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   )
// }

export default function Home() {
  return (
    <div>
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container sx={{ mt: 4, mb: 4 }}>
          <Box>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <AddProperty />
              <PropertiesTable />
            </Paper>
          </Box>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </Container>
      </Box>
    </div>
  )
}
