import * as React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import About from './pages/About'
import Customers from './pages/Customers'
import Footer from './components/layout/footer'
import Home from './pages/Home'
import Layout from './components/layout'
import Login from './pages/Login'
import Profile from './pages/Profile'
import Signup from './pages/Signup'
import Properties from './pages/Properties'

export const UserContext = React.createContext({})

function App() {
  const [user, setUser] = React.useState()

  React.useEffect(() => {
    const localStateUser = JSON.parse(localStorage.getItem('veerii_user'))
    if (localStateUser) setUser(localStateUser)
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Layout>
        <BrowserRouter>
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Login />} />
          </Routes>
          {user && (
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/properties" element={<Properties />} />
              <Route path="*" element={<h1> sorry page not found</h1>} />
            </Routes>
          )}
        </BrowserRouter>
      </Layout>
      <Footer />
    </UserContext.Provider>
  )
}

export default App
