import { useContext } from 'react'
import { Button, Avatar } from '@mui/material'
import Stack from '@mui/material/Stack'
import { redirect } from 'react-router-dom'

import { UserContext } from '../../../App'
import styles from './header.module.scss'

export default function Header() {
  const { user, setUser } = useContext(UserContext)

  const handleSignOut = () => {
    setUser(false)
    localStorage.clear()
    redirect('/login')
  }

  return (
    <header>
      <Stack direction="row" spacing={2} className={styles.header}>
        <Button variant="h6" color="inherit" href="/about">
          about
        </Button>
        <Button variant="h6" color="inherit" href="/profile">
          Profile
        </Button>
      </Stack>
      <Stack direction="row" spacing={2}>
        {!user ? (
          <Button variant="h6" color="inherit" href="/login">
            Login
          </Button>
        ) : (
          <Button variant="h6" color="inherit" href="/" onClick={handleSignOut}>
            Logout {user.firstName}
            <Avatar
              alt="Remy Sharp"
              style={{ marginLeft: 10 }}
              src="https://randomuser.me/api/portraits/men/1.jpg"
            />
          </Button>
        )}
      </Stack>
    </header>
  )
}
