import { useContext, useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  Alert,
  Snackbar,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  TextField,
  Button,
  Avatar,
} from '@mui/material'

import { UserContext } from '../App'

const theme = createTheme()

export default function Profile() {
  const { user, setUser } = useContext(UserContext)
  const [form, setForm] = useState({})
  const [success] = useState(false)

  useEffect(() => {
    if (user) setForm(user)
  }, [user])

  const handleSubmit = event => {
    event.preventDefault()
    delete form._id
    fetch(`${process.env.REACT_APP_API}/api/realtors/updateRealtor`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.accessToken,
      },
      body: JSON.stringify(form),
    })
      .then(res => res.json())
      .then(updatedUser => {
        if (updatedUser.accessToken) {
          localStorage.setItem('veerii_user', JSON.stringify(updatedUser))
          setUser(updatedUser)
          setForm(updatedUser)
        } else {
          console.error(updatedUser)
        }
      })
      .catch(err => console.error(err))
  }

  const handleForm = e => {
    const target = e.target
    setForm({ ...form, [target.name]: target.value })
  }

  useEffect(() => {
    setForm(user)
  }, [user])

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={success} autoHideDuration={6000} onClose={!success}>
        <Alert onClose={!success} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt="Remy Sharp"
            sx={{ width: 100, height: 100 }}
            src="https://randomuser.me/api/portraits/men/1.jpg"
          />
          <Typography component="h1" variant="h5">
            My Profile
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            {form.firstName && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={form.firstName}
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={handleForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={form.lastName}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={handleForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    defaultValue={form.email}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    disabled
                    onChange={handleForm}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    defaultValue={form.phone}
                    required
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    type="phone"
                    id="phone"
                    autoComplete="phone-number"
                    onChange={handleForm}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={form.agency}
                    autoComplete="company-name"
                    name="agency"
                    required
                    fullWidth
                    id="agency"
                    label="Company Name"
                    onChange={handleForm}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={form.realtorId}
                    required
                    fullWidth
                    id="realtor"
                    label="Realtor Number"
                    name="realtorNo"
                    type="number"
                    onChange={handleForm}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    defaultValue={form.url}
                    required
                    fullWidth
                    id="url"
                    label="Preferred URL"
                    name="url"
                    onChange={handleForm}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Update
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Delete account?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
